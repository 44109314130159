<template>
  <main class="kb-main" id="kb-common-board" v-if="!isLoading">
    <lxp-main-header :show-custom-title="true" :title="boardMst.boardTitle" :show-back="showHistoryBack" />

    <section v-if="boardMst.boardTopCn" class="segment-box my-2 ck-content">
      <div class="view-component" v-html="boardMst.boardTopCn"></div>
    </section>

    <div class="main-content min-component">

      <board-main-list v-if="boardMst.boardLayoutCd == 'mainList'" :board-mst-map="boardMst" :board-cate-list="boardCateList" :is-oper="isOper" :post-grp-no="postGrpNo" />
      <board-timeline v-if="boardMst.boardLayoutCd == 'timeline'" :board-mst-map="boardMst" :board-cate-list="boardCateList" :is-oper="isOper" :post-grp-no="postGrpNo" />
      <board-thumb-list v-if="boardMst.boardLayoutCd == 'thumbList'" :board-mst-map="boardMst" :board-cate-list="boardCateList" :is-oper="isOper" :post-grp-no="postGrpNo" />
      <board-group-list v-if="boardMst.boardLayoutCd == 'groupList'" :board-mst-map="boardMst" :board-cate-list="boardCateList" :is-oper="isOper" :post-grp-no="postGrpNo" />

      <section v-if="boardMst.boardBtmCn" class="content-section section-margin segment-box-outline ck-content">
        <div v-html="boardMst.boardBtmCn" class="px-sm-3"></div>
      </section>
    </div>
  </main>
</template>

<script>
import {computed, onMounted, ref, watch} from "vue";
import {useStore} from 'vuex';
import {useRoute} from "vue-router";
import {useAlert} from "@/assets/js/modules/common/alert";
import LxpMainHeader from "@/components/common/LxpMainHeader";
import boardMainList from '@/components/board/BoardMainList';
import boardTimeline from '@/components/board/BoardTimeline';
import boardThumbList from "@/components/board/BoardThumbList";
import boardGroupList from "@/components/board/BoardGroupList";
import {
  checkIsOper,
  exitBoardPage,
  getBoardCateList,
  getBoardMstInfo,
  validTarget
} from "@/assets/js/modules/board/board-common";

export default {
  name: 'boardMst',
  components: {LxpMainHeader, boardMainList, boardTimeline, boardThumbList, boardGroupList,},
  setup() {
    const store = useStore();
    const route = useRoute();
    const {showMessage} = useAlert();
    const boardId = computed(() => route.params.boardId);
    const postGrpNo = computed(() => route.params.postGrpNo);
    const boardMst = ref(null);
    const boardCateList = ref([]);
    const isOper = ref(false);
    const isLoading = ref(true);

    const checkValidAccess = () => {
      checkIsOper({boardMstSn: boardMst.value.boardMstSn, userSn: store.state.auth.session.userSn}, isOper, () => {
        if(!validTarget(boardMst.value.postViewTrgt, isOper.value)){
          showMessage('게시글 조회 권한이 없습니다.', () => {exitBoardPage()});
        }else{
          isLoading.value = false;
        }
      });
    }

    // 게시판 정보
    const getBoardMst = () => {
      isLoading.value = true;
      getBoardMstInfo(boardId.value, boardMst, () => {
        if(route.params.postGrpNo){
          // 글쓰기 버튼 숨김
          boardMst.value.postWrtYn = 'N';
        }
        getBoardCateList(boardMst.value.boardMstSn, boardCateList, checkValidAccess);
      });
    }

    watch(() => route.params.boardId, () => {
      if(route.name == 'BoardMst') getBoardMst();
    })
    watch(() => route.params.postGrpNo, () => {
      if(route.name == 'BoardMst') getBoardMst();
    })

    onMounted(() => {getBoardMst()});

    return{
      boardMst,
      boardCateList,
      isOper,
      postGrpNo,
      isLoading,
      showHistoryBack: route.query.hisback == '1' ? true : false,
    }

  },
}
</script>